import React, { useEffect } from "react";

function JobViewPopup(props) {
  const { selectedJob, show, onApply } = props;

  const renderKeyValue = (key, value) => {
    if (value) {
      return (
        <div className="col-6">
          <strong>{key} </strong>
          <p>{value}</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (show) {
      const modalElement = document.getElementById("saved-jobs-view");
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }, [show]);

  return (
    <div
      className="modal fade twm-saved-jobs-view"
      id="saved-jobs-view"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel-3"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel-3">
              Candidate Details
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="modal-body-content row">
              {selectedJob?.Name && (
                <div className="col-12">
                  <strong>Name </strong>
                  <p>{selectedJob.Name}</p>
                </div>
              )}
              {renderKeyValue("Gender", selectedJob?.Gender)}
              {renderKeyValue("Designation", selectedJob?.Designation)}
              {renderKeyValue("Specialization", selectedJob?.Specialization)}
              {renderKeyValue("Experience", selectedJob?.Experience)}
              {renderKeyValue("UnderGraduate", selectedJob?.UnderGraduate)}
              {renderKeyValue("Post Graduate", selectedJob?.PostGraduate)}
              {renderKeyValue("PhD", selectedJob?.PhD)}
              {renderKeyValue("Others", selectedJob?.Others)}
              {selectedJob?.District && selectedJob?.State && (
                <div className="col-6">
                  <strong>Location </strong>
                  <p>
                    {selectedJob.District}, {selectedJob.State}
                  </p>
                </div>
              )}
              <div className="col-6">
                <strong>Resume </strong>
                <br />
                <a
                  href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Here
                </a>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="site-button pinte-clr"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobViewPopup;
