import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, AppBar, Tabs, Tab, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import SectionCandicateBasicInfo from "../sections/profile/section-can-basic-info";
import SectionCandidateSocialInfo from "../sections/profile/section-can-social-info";
import SectionCandicateAddressInfo from "../sections/profile/section-can-address-info";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import SectionCandidateEducationInfo from "../sections/profile/section-can-education-info";
import SectionCandidateProfessionalInfo from "../sections/profile/section-can-professional-info";
import SectionCandidateResume from "../sections/profile/section-can-resume";
import SectionCandidateLookingInfo from "../sections/profile/section-can-looking-for";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#33CC66",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "white",
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CanProfilePage() {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads.ads);
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canProfile = useSelector((state) => state.CanDashboard.canProfile);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Profile</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Profile</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 mb-4">
            <NavLink to={ads?.Profile_Header_Link} target="_blank">
              <img
                src={ads?.Profile_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>

          <ThemeProvider theme={theme}>
            <Grid container spacing={2} style={{ marginTop: "2rem" }}>
              <Grid item xs={12}>
                <Box sx={{ bgcolor: "background.paper" }}>
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab label="Basic Information" />
                      <Tab label="Academic Information" />
                      <Tab label="Resume Upload" />
                      <Tab label="Current Profession" />
                      <Tab label="Looking For" />
                      <Tab label="Social Network" />
                      <Tab label="Address Information" />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <SectionCandicateBasicInfo
                      profileData={canProfile?.Basic}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <SectionCandidateEducationInfo
                      Qualifications={canProfile?.Qualifications}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <SectionCandidateResume
                      resumeURL={canProfile?.Resume}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <SectionCandidateProfessionalInfo
                      CurrentProfession={canProfile?.CurrentProfession}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <SectionCandidateLookingInfo
                      LookingFor={canProfile?.LookingFor}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <SectionCandidateSocialInfo
                      socialNetwork={canProfile?.SocialNetwork}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <SectionCandicateAddressInfo
                      Address={canProfile?.Address}
                      userUUID={userUUID}
                    />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
            <NavLink to={ads?.Profile_Footer_Link} target="_blank">
              <img
                src={ads?.Profile_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default CanProfilePage;
