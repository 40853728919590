import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { IconButton, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Index from "../../../candidate/common/no-data/Index";
import { loadScript } from "../../../../../globals/constants";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpManageJob } from "../../../../../reducers/employer/EmpManageJobSlice";
import { fetchGeneralempAds } from "../../../../../reducers/public-user/empAdsSlice";
import JobViewPopup from "../../../../common/popups/popup-job-view";
import ModalPopUp from "../../../../common/ModalPopUp";
import WorkIcon from "@mui/icons-material/Work";

function EmpManageJobPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const manageJobsList = useSelector((state) => state.EmpManageJob);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [selectedJob, setSelectedJob] = useState(null);
  const [open, setOpen] = useState(false);

  const initialValues = {
    displayStatus: "",
  };

  const validationSchema = Yup.object({
    displayStatus: Yup.string().required("Required"),
  });

  const handleApplyButton = (data) => {
    if (onApply) {
      onApply(data); // Call the parent callback function
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpManageJob(userUUID));
  }, [dispatch, userUUID, loading]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch, loading]);

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  const handleViewJob = () => {
    setOpen(true);
  };

  return (
    <>
      {manageJobsList?.loading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Manage Jobs</h2>
                <div className="breadcrumbs">
                  <a href="/">Home</a>
                  <a href="/">Dashboard</a>
                  <span>My Job Listing</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.ManageJobs_Header_Link} target="_blank">
                  <img
                    src={empAds?.ManageJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {manageJobsList?.EmpManageJob?.length > 0 ? (
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">
                  <i className="fa fa-suitcase" /> Manage Jobs
                </h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30 ">
                <div className="twm-D_table p-a20 table-responsive">
                  <table
                    id="jobs_bookmark_table"
                    className="table table-bordered twm-bookmark-list-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Company Name</th>
                        <th>Job Title</th>
                        <th>Designation</th>
                        <th>Experience</th>
                        <th>Display Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {manageJobsList?.EmpManageJob?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.Company}</td>
                          <td>{item.JobTitle}</td>
                          <td>{item.Designation}</td>
                          <td>{item.Experience}</td>
                          <td>{item.Posts}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="twm-table-controls">
                              <ul className="twm-DT-controls-icon list-unstyled">
                                <li>
                                  <a
                                    data-bs-toggle="modal"
                                    href="#saved-jobs-view"
                                    role="button"
                                    className="custom-toltip"
                                  >
                                    <Tooltip title={"View"}>
                                      <IconButton
                                        onClick={() => handleViewClick(item)}
                                      >
                                        <RemoveRedEyeIcon
                                          style={{
                                            fontSize: "15px",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </a>
                                </li>
                                <li>
                                  <Tooltip title={"Job Display Status"}>
                                    <IconButton
                                      onClick={() => handleViewJob(item)}
                                    >
                                      <WorkIcon
                                        style={{
                                          fontSize: "15px",
                                          marginTop: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Sl.No</th>
                        <th>Company Name</th>
                        <th>Job Title</th>
                        <th>Designation</th>
                        <th>Experience</th>
                        <th>Display Status</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.ManageJobs_Footer_Link} target="_blank">
              <img
                src={empAds?.ManageJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
          <JobViewPopup selectedJob={selectedJob} show={true} />
          <ModalPopUp
            title={"Job Display Status"}
            open={open}
            close={() => {
              setOpen(false);
            }}
            children={
              <>
                <div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true);
                      handleApplyButton(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <strong> Job Display Status </strong>
                          <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="displayStatus"
                            >
                              <option value="">Select</option>
                              <option value={0}>Yes</option>
                              <option value={1}>No</option>
                            </Field>
                            <ErrorMessage
                              name="displayStatus"
                              component="div"
                              className="field-error"
                            />
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  );
}
export default EmpManageJobPage;
