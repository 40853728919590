import React, { useEffect } from "react";
import { Grid, AppBar, Tabs, Tab, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../globals/constants";
import CompanyDetails from "../../employer/components/profile/company-details";
import SocialNetwork from "../../employer/components/profile/social-network";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import Address from "../../employer/components/profile/Address";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#33CC66",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "white",
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EmpCompanyProfilePage() {
  const dispatch = useDispatch();
  const empAds = useSelector((state) => state.empAds.empAds);
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const EmpProfile = useSelector((state) => state.EmpDashboard.empProfile);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      <header className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Company Profile!</h2>
            <nav className="breadcrumbs">
              <a href="#">Home</a>
              <a href="#">Dashboard</a>
              <span>Company Profile!</span>
            </nav>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            {empAds?.Profile_Header && empAds?.Profile_Header_Link && (
              <NavLink to={empAds.Profile_Header_Link} target="_blank">
                <img
                  src={empAds.Profile_Header}
                  alt="Banner"
                  className="img-fluid"
                />
              </NavLink>
            )}
          </div>
        </div>
      </header>

      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          <Grid item xs={12}>
            <Box sx={{ bgcolor: "background.paper" }}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Basic Information" />
                  <Tab label="Social Network" />
                  <Tab label="Address Information" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <CompanyDetails
                  Address={EmpProfile?.Basic}
                  userUUID={userUUID}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SocialNetwork
                  socialNetwork={EmpProfile?.SocialNetwork}
                  userUUID={userUUID}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Address Address={EmpProfile?.Address} userUUID={userUUID} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>

      <main>
        <section className="col-xl-12 col-lg-12 col-md-12 mb-4">
          {empAds?.Profile_Header && empAds?.Profile_Header_Link && (
            <NavLink to={empAds.Profile_Header_Link} target="_blank">
              <img
                src={empAds.Profile_Header}
                alt="Banner"
                className="img-fluid"
              />
            </NavLink>
          )}
        </section>
      </main>
    </>
  );
}

export default EmpCompanyProfilePage;
