import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  employer,
  empRoute,
  publicUser,
} from "../../../../globals/route-names";
import JobZImage from "../../../common/jobz-img";
import { loadScript, setMenuActive } from "../../../../globals/constants";

function EmpSidebarSection(props) {
  const currentpath = useLocation().pathname;
  const [activeMenuItem, setActiveMenuItem] = useState(currentpath);

  useEffect(() => {
    loadScript("js/custom.js");
    loadScript("js/emp-sidebar.js");
  }, []);

  const handleMenuItemClick = (path) => {
    setActiveMenuItem(path);
    // Close sidebar on small screens
    if (window.innerWidth < 700) {
      props.onMenuItemClick();
    }
  };

  return (
    <>
      <nav
        id="sidebar-admin-wraper"
        className={props.sidebarActive ? "" : "active"}
      >
        <div className="page-logo">
          <NavLink to={publicUser.HOME2}>
            <JobZImage
              id="images"
              src="images/lacnar-img/logo-skin-6.png"
              alt=""
            />
          </NavLink>
        </div>
        <div className="admin-nav scrollbar-macosx">
          <ul>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.DASHBOARD)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.DASHBOARD))}
            >
              <NavLink to={empRoute(employer.DASHBOARD)}>
                <i className="fa fa-home" />
                <span className="admin-nav-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(activeMenuItem, empRoute(employer.DEAL))}
              onClick={() => handleMenuItemClick(empRoute(employer.DEAL))}
            >
              <NavLink to={empRoute(employer.DEAL)}>
                <i className="fa fa-user-tie" />
                <span className="admin-nav-text">Offers</span>
              </NavLink>
            </li>
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.POST_A_JOB)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.POST_A_JOB))}
            >
              <NavLink to={empRoute(employer.POST_A_JOB)}>
                <i className="fa fa-suitcase" />
                <span className="admin-nav-text">Post a New Job</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.MANAGE_JOBS)
              )}
              onClick={() =>
                handleMenuItemClick(empRoute(employer.MANAGE_JOBS))
              }
            >
              <NavLink to={empRoute(employer.MANAGE_JOBS)}>
                <i className="fa fa-share-square" />
                <span className="admin-nav-text">Manage Jobs</span>
              </NavLink>
            </li>
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.CANDIDATES)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.CANDIDATES))}
            >
              <NavLink to={empRoute(employer.CANDIDATES)}>
                <i className="fa fa-user-friends" />
                <span className="admin-nav-text">Candidates Search</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.BOOKMARKS)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.BOOKMARKS))}
            >
              <NavLink to={empRoute(employer.BOOKMARKS)}>
                <i className="fa fa-bookmark" />
                <span className="admin-nav-text">Bookmark Resumes</span>
              </NavLink>
            </li>
            {/* <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.RESUME_ALERTS)
              )}
              onClick={() =>
                handleMenuItemClick(empRoute(employer.RESUME_ALERTS))
              }
            >
              <NavLink to={empRoute(employer.RESUME_ALERTS)}>
                <i className="fa fa-bell" />
                <span className="admin-nav-text">Resume Alerts</span>
              </NavLink>
            </li> */}
            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.PAYMENTS)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.PAYMENTS))}
            >
              <NavLink to={empRoute(employer.PAYMENTS)}>
                <i className="fa fa-money-bill-alt" />
                <span className="admin-nav-text">Payment History</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.PRICING)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.PRICING))}
            >
              <NavLink to={empRoute(employer.PRICING)}>
                <i className="fas fa-receipt" />
                <span className="admin-nav-text">Pricing</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.INVENTORY)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.INVENTORY))}
            >
              <NavLink to={empRoute(employer.INVENTORY)}>
                <i className="fa fa-cart-arrow-down" />
                <span className="admin-nav-text">Inventory</span>
              </NavLink>
            </li>

            <li>
              <hr />
            </li>
            <li
              className={setMenuActive(
                activeMenuItem,
                empRoute(employer.TICKETS)
              )}
              onClick={() => handleMenuItemClick(empRoute(employer.TICKETS))}
            >
              <NavLink to={empRoute(employer.TICKETS)}>
                <i className="fa fa-file-download" />
                <span className="admin-nav-text">Tickets</span>
              </NavLink>
            </li>
            <li
              className={setMenuActive(activeMenuItem, empRoute(employer.FAQ))}
              onClick={() => handleMenuItemClick(empRoute(employer.FAQ))}
            >
              <NavLink to={empRoute(employer.FAQ)}>
                <i className="fa fa-file-download" />
                <span className="admin-nav-text">FAQ</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default EmpSidebarSection;
